import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import styled from "@emotion/styled"
import { Text } from "rebass";

const IconSpinner = styled.span`
  font-size: 124px;
  display: inline-block;
  animation: spin 1s infinite linear;
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@keyframes twist-up {
  to {
    transform: rotateX(360deg);
  }
}
`

const IndexPage = () => (
  <Layout>
    <SEO title="Fehrfeld 24" />
    <Text fontSize={[6, 7, 8]}>Fehrfeld 24</Text>
    <Text fontSize={[1, 2, 3]}>Welcome to this nice website where your dreams will come true.</Text>
    <IconSpinner role="img" aria-label="spining dino">🦖</IconSpinner>
    <Text fontSize={[1, 2, 3]}>Experience crazy things.</Text>
    <Text fontSize={[1, 2, 3]}>Them content – where is it?</Text>
    <IconSpinner role="img" aria-label="spining dino">🌚</IconSpinner>
  </Layout>
);

export default IndexPage;
